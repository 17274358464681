/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import '~quill/dist/quill.snow.css';

@import 'theme/variables.scss';

body{
  padding-top: 12px;
}

ion-content {
  --padding-bottom: 2em;
  margin-bottom: 2em;
}

.centered-grid {
  display: flex;
  justify-content: center;
}
.ql-editor li{
  margin-top: 8px; // change to change list item spacing

}

.ql-tooltip.ql-editing{
    z-index: 9999;
}

quill-editor{
  margin-bottom: 36px;
}

// quil js checkbox styles
.ql-editor ul[data-checked=true] > li::before{
  width:20px;
  height: 14px;
  top: 3px;
  position: relative;
  content: url("./assets/images/svgs/select_check_box.svg");
}
.ql-editor ul[data-checked=false] > li::before{
  width:20px;
  height: 14px;
  top: 3px;
  position: relative;
  content: url("./assets/images/svgs/check_box_outline.svg");
}
.ql-container{
  font-size:var(--ql-editor-default-font-size);
}
.ql-editor{
  font-size:var(--ql-editor-default-font-size);
}

:root {
  --ql-editor-default-font-size:16px!important;
}

/*
Stuckture of Publisched/not publisched item in progress

| <app-show-not-publisched-icon >
|     |-- div
|       |-- .is-not-published   (icon)
*/
.is-not-published{
  font-style: italic!important;
}
div  > .is-not-published {
  width: 18px;
  height: 18px !important;
  top: -4px;
  position: relative;
  z-index: 999;
  left: 12px;

}




.row-border {
  border-bottom: 1px solid #d5d5d5;
  padding-bottom: 7px;
  padding-top: 7px;
}

.vertically-centered {
  align-self: center;
}

.verbandsapp-header, .verbandsapp-toolbar{
  background-color: var(--ion-color-primary) !important;
  --background: var(--ion-color-primary) !important;
  color: white !important;
  text-align: center !important;
  padding: 0px 0 !important;
  height: 56px;
}

.verbandsapp-toolbar-light{
  background-color: var(--ion-color-light) !important;
  --background: var(--ion-color-light) !important;
  color: var(--ion-color-dark) !important;
  text-align: center;
  padding: 0px 0 !important;
  height: 56px;

}

.verbandsapp-border-bottom{
  border-bottom: 1px solid var(--ion-color-medium);
}

.verbandsapp-toolbar-title{
  height: 100%;
  font-size: 22px !important;
  padding: 0;
}
.verbandsapp-header > ion-row{
  height: 100%;
}
.verbandsapp-header ion-buttons{
  height: 100%;
}
//.verbandsapp-header ion-button{
//  margin: 3px auto;
//}

//.verbandsapp-header ion-button > .ios{
//  margin: 6px auto;
//}

ion-toolbar ion-col, .verbandsapp-toolbar ion-col, .verbandsapp-toolbar ion-row, .verbandsapp-toolbar ion-col.ios.hydrated, .verbandsapp-toolbar .toolbar-container{
  padding: 0 !important;
  margin: 0 !important;
}

.verbandsapp-toolbar-icon {
  color: white !important;
}

ion-menu ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #ffffff));
}

ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 0;
  --padding-bottom: 20px;
}

ion-menu.md ion-list {
  padding: 0 0 20px;
}

ion-menu.md ion-list-header,
ion-menu.md ion-note {
  padding-left: 10px;
}

ion-menu.md ion-list#inbox-list {
  border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}

ion-menu.md ion-list#inbox-list ion-list-header {
  font-size: 22px;
  font-weight: 600;

  min-height: 20px;
}

ion-menu.md ion-list#labels-list ion-list-header {
  font-size: 16px;

  margin-bottom: 28px;

  color: #757575;

  min-height: 26px;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-secondary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-secondary);
}

ion-menu.md ion-item ion-icon {
  color: #616e7e;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item.selected ion-icon {
  color: var(--ion-color-secondary);
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}
ion-list-header{
  margin-bottom: 28px;
}
ion-menu.ios ion-list#labels-list ion-list-header {
  margin-bottom: 28px;
}

ion-menu.ios ion-list-header{
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-item-divider > ion-label {
  color: var(--ion-color-secondary) !important;
  width: 100%;
  font-size: larger;
  margin-top: 12px;
  margin-bottom: 12px;
  //margin-left: 6px;
  font-variant-caps: all-petite-caps;
}

ion-item.selected {
  --color: var(--ion-color-secondary);
}
.verbandsapp-highlighted-item{
  --background: rgba(var(--ion-color-tertiary-rgb), 0.17);
}

form > ion-item > ion-label {
  color: var(--ion-color-secondary) !important;
}

form ion-label {
  --color: var(--ion-color-secondary) !important;
}

ion-toggle ion-label {
  --color: var(--ion-color-secondary) !important;
}

ion-input .label-text {
  color: var(--ion-color-secondary) !important;
}

.validation-error {
  color: var(--ion-color-danger);
  font-size: small;
}

.selectable:hover{
  cursor: pointer;
}

.cal-month-view .cal-day-cell {
  min-height: 0 !important;
}

.cal-month-view .cal-day-cell.cal-today .cal-day-number {
  // font-size: 1.2em !important;
  font-size: 0.8em !important;
  margin-top: 5px;

}


.feed-unread {
  margin-left: 5px;
  border-left: 5px solid var(--ion-color-secondary);
}
.feed-read {
  margin-left: 5px;
  border-left: 5px solid transparent;
}
//
.ql-snow .ql-tooltip[data-mode=link]::before {
  content: "Link eingeben:";
}
.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  border-right: 0;
  content: 'Speichern';
  padding-right: 0;
}


.ql-tooltip {


  left: 0 !important;
}
.no-tooltip .ql-tooltip {
  display: none !important;
}

// disable editing set links by hiding the popup
.ql-tooltip:not(.ql-editing){
display: none;
}


.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options{
  z-index: 100;
}

app-show-not-publisched-icon{
  position: fixed;
  z-index: 999;
}

//// - - am Ende lassen !!!
@import "brand/verbandsapp-design.scss";

// Global styling for form elements
ion-select {
  --placeholder-color: var(--ion-color-secondary) !important;
  --color: var(--ion-color-secondary) !important;
}

ion-select::part(label),
ion-select::part(text),
ion-select::part(placeholder) {
  color: var(--ion-color-secondary) !important;
}

ion-toggle::part(label) {
  color: var(--ion-color-secondary) !important;
}

// For floating labels in ion-select
.label-floating.sc-ion-label-md-h, 
.label-floating.sc-ion-label-ios-h,
.label-stacked.sc-ion-label-md-h,
.label-stacked.sc-ion-label-ios-h {
  color: var(--ion-color-secondary) !important;
}
