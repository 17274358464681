// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

@font-face {
  font-family: "Source Sans Pro";
  src: url("../assets/source-sans-pro/SourceSansPro-Regular.otf");
  }

  .verbandsapp-font, h1, h2, h3, h4, ion-header, .verbandsapp-header, ion-item-divider {
    font-family:"Source Sans Pro" !important;
  }

  html{
    font-family: "Source Sans Pro";
  }

$orange: #ee7203;
$gray_background: #F5F5F5;
$gray_1: #c7c7c7;
$gray_2: #8c8c8c;
$gray_3: #3d3d3d;

$turquoise: #006699;//#0a80ab; // #00b9ff;

$primary:       $turquoise;
$secondary:     $orange;

.color-orange{
  color: $orange;
}
.color-turquoise{
  color: $turquoise;
}
.color-gray{
  color: $gray_1;
}

:root {
  --ion-color-primary: #006699;
  --ion-color-primary-rgb: 0,102,153;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  --ion-color-secondary: #ee7203;
  --ion-color-secondary-rgb: 238,114,3;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0,0,0;
  --ion-color-secondary-shade: #d16403;
  --ion-color-secondary-tint: #f0801c;

  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82,96,255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45,211,111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255,196,9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235,68,90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34,36,40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146,148,156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255,255,255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244,245,248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

// TEMP VAK =>:
//:root {
//
//  //--ion-font-family: Montserrat;
//  --ion-color-primary: #00234e;
//  --ion-color-primary-rgb: 0,35,78;
//  --ion-color-primary-contrast: #ffffff;
//  --ion-color-primary-contrast-rgb: 255,255,255;
//  --ion-color-primary-shade: #001f45;
//  --ion-color-primary-tint: #1a3960;
//
//  --ion-color-secondary: #ee7f02;
//  --ion-color-secondary-rgb: 238,127,2;
//  --ion-color-secondary-contrast: #000000;
//  --ion-color-secondary-contrast-rgb: 0,0,0;
//  --ion-color-secondary-shade: #d17002;
//  --ion-color-secondary-tint: #f08c1b;
//
//  --ion-color-tertiary: #6b82b4;
//  --ion-color-tertiary-rgb: 107,130,180;
//  --ion-color-tertiary-contrast: #000000;
//  --ion-color-tertiary-contrast-rgb: 0,0,0;
//  --ion-color-tertiary-shade: #5e729e;
//  --ion-color-tertiary-tint: #7a8fbc;
//
//  --ion-color-success: #76c097;
//  --ion-color-success-rgb: 118,192,151;
//  --ion-color-success-contrast: #000000;
//  --ion-color-success-contrast-rgb: 0,0,0;
//  --ion-color-success-shade: #68a985;
//  --ion-color-success-tint: #84c6a1;
//
//  --ion-color-warning: #fba704;
//  --ion-color-warning-rgb: 251,167,4;
//  --ion-color-warning-contrast: #000000;
//  --ion-color-warning-contrast-rgb: 0,0,0;
//  --ion-color-warning-shade: #dd9304;
//  --ion-color-warning-tint: #fbb01d;
//
//  --ion-color-danger: #bc2f04;
//  --ion-color-danger-rgb: 188,47,4;
//  --ion-color-danger-contrast: #ffffff;
//  --ion-color-danger-contrast-rgb: 255,255,255;
//  --ion-color-danger-shade: #a52904;
//  --ion-color-danger-tint: #c3441d;
//
//  --ion-color-dark: #182432;
//  --ion-color-dark-rgb: 24,36,50;
//  --ion-color-dark-contrast: #ffffff;
//  --ion-color-dark-contrast-rgb: 255,255,255;
//  --ion-color-dark-shade: #15202c;
//  --ion-color-dark-tint: #2f3a47;
//
//  --ion-color-medium: #b5b6b6;
//  --ion-color-medium-rgb: 181,182,182;
//  --ion-color-medium-contrast: #000000;
//  --ion-color-medium-contrast-rgb: 0,0,0;
//  --ion-color-medium-shade: #9fa0a0;
//  --ion-color-medium-tint: #bcbdbd;
//
//  --ion-color-light: #e7e8e9;
//  --ion-color-light-rgb: 231,232,233;
//  --ion-color-light-contrast: #000000;
//  --ion-color-light-contrast-rgb: 0,0,0;
//  --ion-color-light-shade: #cbcccd;
//  --ion-color-light-tint: #e9eaeb;
//}
//




//@media (prefers-color-scheme: dark) {
//  /*
//   * Dark Colors
//   * -------------------------------------------
//   */
//
//  body {
//    --ion-color-primary: #428cff;
//    --ion-color-primary-rgb: 66,140,255;
//    --ion-color-primary-contrast: #ffffff;
//    --ion-color-primary-contrast-rgb: 255,255,255;
//    --ion-color-primary-shade: #3a7be0;
//    --ion-color-primary-tint: #5598ff;
//
//    --ion-color-secondary: #50c8ff;
//    --ion-color-secondary-rgb: 80,200,255;
//    --ion-color-secondary-contrast: #ffffff;
//    --ion-color-secondary-contrast-rgb: 255,255,255;
//    --ion-color-secondary-shade: #46b0e0;
//    --ion-color-secondary-tint: #62ceff;
//
//    --ion-color-tertiary: #6a64ff;
//    --ion-color-tertiary-rgb: 106,100,255;
//    --ion-color-tertiary-contrast: #ffffff;
//    --ion-color-tertiary-contrast-rgb: 255,255,255;
//    --ion-color-tertiary-shade: #5d58e0;
//    --ion-color-tertiary-tint: #7974ff;
//
//    --ion-color-success: #2fdf75;
//    --ion-color-success-rgb: 47,223,117;
//    --ion-color-success-contrast: #000000;
//    --ion-color-success-contrast-rgb: 0,0,0;
//    --ion-color-success-shade: #29c467;
//    --ion-color-success-tint: #44e283;
//
//    --ion-color-warning: #ffd534;
//    --ion-color-warning-rgb: 255,213,52;
//    --ion-color-warning-contrast: #000000;
//    --ion-color-warning-contrast-rgb: 0,0,0;
//    --ion-color-warning-shade: #e0bb2e;
//    --ion-color-warning-tint: #ffd948;
//
//    --ion-color-danger: #ff4961;
//    --ion-color-danger-rgb: 255,73,97;
//    --ion-color-danger-contrast: #ffffff;
//    --ion-color-danger-contrast-rgb: 255,255,255;
//    --ion-color-danger-shade: #e04055;
//    --ion-color-danger-tint: #ff5b71;
//
//    --ion-color-dark: #f4f5f8;
//    --ion-color-dark-rgb: 244,245,248;
//    --ion-color-dark-contrast: #000000;
//    --ion-color-dark-contrast-rgb: 0,0,0;
//    --ion-color-dark-shade: #d7d8da;
//    --ion-color-dark-tint: #f5f6f9;
//
//    --ion-color-medium: #989aa2;
//    --ion-color-medium-rgb: 152,154,162;
//    --ion-color-medium-contrast: #000000;
//    --ion-color-medium-contrast-rgb: 0,0,0;
//    --ion-color-medium-shade: #86888f;
//    --ion-color-medium-tint: #a2a4ab;
//
//    --ion-color-light: #222428;
//    --ion-color-light-rgb: 34,36,40;
//    --ion-color-light-contrast: #ffffff;
//    --ion-color-light-contrast-rgb: 255,255,255;
//    --ion-color-light-shade: #1e2023;
//    --ion-color-light-tint: #383a3e;
//  }
//
//  /*
//   * iOS Dark Theme
//   * -------------------------------------------
//   */
//
//  .ios body {
//    --ion-background-color: #000000;
//    --ion-background-color-rgb: 0,0,0;
//
//    --ion-text-color: #ffffff;
//    --ion-text-color-rgb: 255,255,255;
//
//    --ion-color-step-50: #0d0d0d;
//    --ion-color-step-100: #1a1a1a;
//    --ion-color-step-150: #262626;
//    --ion-color-step-200: #333333;
//    --ion-color-step-250: #404040;
//    --ion-color-step-300: #4d4d4d;
//    --ion-color-step-350: #595959;
//    --ion-color-step-400: #666666;
//    --ion-color-step-450: #737373;
//    --ion-color-step-500: #808080;
//    --ion-color-step-550: #8c8c8c;
//    --ion-color-step-600: #999999;
//    --ion-color-step-650: #a6a6a6;
//    --ion-color-step-700: #b3b3b3;
//    --ion-color-step-750: #bfbfbf;
//    --ion-color-step-800: #cccccc;
//    --ion-color-step-850: #d9d9d9;
//    --ion-color-step-900: #e6e6e6;
//    --ion-color-step-950: #f2f2f2;
//
//    --ion-toolbar-background: #0d0d0d;
//
//    --ion-item-background: #000000;
//
//    --ion-card-background: #1c1c1d;
//  }
//
//
//  /*
//   * Material Design Dark Theme
//   * -------------------------------------------
//   */
//
//  .md body {
//    --ion-background-color: #121212;
//    --ion-background-color-rgb: 18,18,18;
//
//    --ion-text-color: #ffffff;
//    --ion-text-color-rgb: 255,255,255;
//
//    --ion-border-color: #222222;
//
//    --ion-color-step-50: #1e1e1e;
//    --ion-color-step-100: #2a2a2a;
//    --ion-color-step-150: #363636;
//    --ion-color-step-200: #414141;
//    --ion-color-step-250: #4d4d4d;
//    --ion-color-step-300: #595959;
//    --ion-color-step-350: #656565;
//    --ion-color-step-400: #717171;
//    --ion-color-step-450: #7d7d7d;
//    --ion-color-step-500: #898989;
//    --ion-color-step-550: #949494;
//    --ion-color-step-600: #a0a0a0;
//    --ion-color-step-650: #acacac;
//    --ion-color-step-700: #b8b8b8;
//    --ion-color-step-750: #c4c4c4;
//    --ion-color-step-800: #d0d0d0;
//    --ion-color-step-850: #dbdbdb;
//    --ion-color-step-900: #e7e7e7;
//    --ion-color-step-950: #f3f3f3;
//
//    --ion-item-background: #1e1e1e;
//
//    --ion-toolbar-background: #1f1f1f;
//
//    --ion-tab-bar-background: #1f1f1f;
//
//    --ion-card-background: #1e1e1e;
//  }
//}
